<template>
	<div class="house_item_all">
		<div class="house_item" @click="toDetail">
			
			<div class="collect" v-if="houseItem.is_hot==2">
				<img src="../assets/icons/important.png">
			</div>
			<div class="house_img">
				<img :src="houseItem.thumb[0]">
			</div>
			<div class="house_title">
				{{houseItem.title}}
			</div>
			<div class="house_price">
				<div class="house_price_left" v-if="houseItem.price!='0.00'">
					${{houseItem.price}}
				</div>
				<div class="house_price_right" v-if="houseItem.company_logo_url">
					<img :src="houseItem.company_logo_url">
				</div>
			</div>
			<div class="cate">
				{{houseItem.cate_name}}
			</div>
			<div class="box">
				<div class="address">{{houseItem.province_name}}</div>
				<div class="address">{{houseItem.create_time}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['houseItem'],
		data() {
			return {
				information_id: '',
			}
		},

		methods: {
			toDetail() {
				console.log(111111);
				// if(!this.$store.state.userinfo){
				//    this.$message.error(this.$t('other.noLoginPleaseToLogin'));
				//     setTimeout(() => {
				// 		this.$router.push({
				// 			path: "/accountLogin"
				// 		});
				// 	}, 1500)  
				//    return false;
				// }
				this.$router.push({
					path: "/detail",
					// path: '/detail',
					query: {
						information_id: this.houseItem.id,
					}
				});
				
				// const path=this.$router.resolve({
				// 	path: '/detail',
					
				// 	query: {
				// 		information_id: this.houseItem.id,
				// 	}
				// })
				
				// console.log(path)
				// window.open(path.href,'_blank')
			}
		}
	}
</script>

<style scoped="" lang="less">
	.collect {
		position: absolute;
		top: 24px;
		left: 24px;

		img {
			width: 32px;
			height: 32px;
		}
	}

	.house_item_all {}

	.house_item {
		position: relative;
		cursor: pointer;
		box-sizing: border-box;
		margin-right: 10px;
		margin-bottom: 10px;
		width: 228px;
		height: 438px;
		padding: 20px 20px 18px 20px;
		background-color: #FFFFFF;

		.house_img {
			width: 192px;
			height: 192px;

			img {
				width: 192px;
				height: 192px;
				object-fit: cover;
			}
		}

		.house_title {
			text-align: left;
			line-height: 26px;
			color: #333333;
			font-size: 14px;
			margin: 16px 0 12px;
			word-break: break-all;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			/* 这里是超出几行省略 */
			overflow: hidden;
		}

		.house_price {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.house_price_left {
				color: #F39800;
				font-weight: bold;
			}

			.house_price_right {
				width: 74px;
				height: 64px;

				img {
					object-fit: contain;
					width: 100%;
					height: 100%;
				}
			}

		}
	}

	.cate {
		text-align: left;
		margin: 16px 0;
		color: #333333;
		font-size: 14px;
		font-weight: bold;
	}

	.box {
		display: flex;
		justify-content: space-between;
		font-size: 14px;
		margin-bottom: 16px;

		.address {}
	}
</style>
